import { PureComponent } from 'react';
// import Layout from '@shares/layout';

export default class ErrorPage extends PureComponent {
  render() {
    return (
      // <Layout prefix="th">
      <div>Error</div>
      // </Layout>
    );
  }
}
